
                @import '@style/mixins';
                @import '@skillbox/front-box/dist/Functional/UiVariables/style';
              
.universal-list-v2__title {
  margin-bottom: 32px;
  @include media(lg) {
    width: 776px;
    margin-bottom: 56px;
  }
}

.universal-list-v2__list {
  @include ulres;

  @include media(lg) {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
  }

  &--odd {
    @include media(xl) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &--numbers {
    counter-reset: numbers;
  }
}

.universal-list-v2__item {
  padding: 20px;
  border-radius: 20px;
  background: var(--bg-secondary-color);
  display: flex;
  align-items: flex-start;
  column-gap: 12px;


  @include media(lg) {
    padding: 32px;
    border-radius: 32px;
    flex-direction: column;
    row-gap: 32px;
  }

  &:not(:last-child) {
    @include media(md-max) {
      margin-bottom: 8px;
    }
  }
}

.universal-list-v2__list--numbered .universal-list-v2__item {
  counter-increment: numbers;

  &::before {
    display: inline-block;
    min-width: 20px;
    margin: 2px;
    border-radius: 100px;
    color: var(--text-invert-color);
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    background-color: var(--bg-invert-color);
    content: counter(numbers);

    @include media(lg) {
      min-width: 28px;
      font-size: 14px;
      line-height: 28px;
    }
  }
}

.universal-list-v2__icon {
  display: block;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: var(--icon-main-color);

  @include media(lg) {
    width: 32px;
    height: 32px;
  }
}

.universal-list-v2__item__content {
  @include media(md, md-max) {
    width: 376px;
  }

  @include media(lg) {
    width: 100%;
  }
}

.universal-list-v2__subtitle {
  display: block;
}

.universal-list-v2__desc {
  color: var(--text-secondary-color);
}
